<template>
    <div>
        <template v-if="type === 'name' || type === 'id'">
            <router-link :to="{ name: 'dealer', params: { dealer_id: item.id }}">
                <span v-if="type === 'name'">{{ item.name }}</span>
                <span v-else>{{ item.id }}</span>
            </router-link>
        </template>
        <template v-else-if="type === 'link'">
            <a
                target="_blank"
                :href="item.site_base_url">
                {{ getDomain(item.site_base_url) }}
            </a>
        </template>
        <template v-else-if="type === 'copy'">
            <div class="ellipsis">
                <span
                    v-clipboard:copy="item.external_id"
                    v-clipboard:success="onCopy"
                    :title="item.external_id">
                    {{ item.external_id }}
                </span>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            default: ''
        }
    },
    methods: {
        getDomain(url) {
            try {
                const urlObj = new URL(url);
                return urlObj.hostname;
            } catch {
                return '';
            }
        },
        onCopy(e) {
            this.$flash(`${e.text} copied to clipboard!`, 'green');
        }
    }
};
</script>

<style lang="scss" scoped>
.ellipsis {
    position: relative;
    &:before {
        content: '&nbsp;';
        visibility: hidden;
    }
    span {
        position: absolute;
        left: 0;
        right: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }
}
</style>